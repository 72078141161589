<template>
    <v-container fluid>
      <h1 class="display-1 font-weight-bold mx-1 mb-3">Authentication Required</h1>
      <p>This feature requires Xero API features</p>
      <v-btn
        color="success"
        class="mr-4"
        @click='requestToken'
      >
        Xero Authentication
      </v-btn>
    </v-container>
</template>

<script>
import OAuth from '@/api/oauth'
// import Accounting from '@/api/accounting'
export default {
  components: {},
  data () {
    return {}
  },
  async created () {
    this.$store.dispatch('setCurrentRedirect', this.$router.history.current.path)
  },
  computed: {},
  watch: {},
  methods: {
    requestToken () {
      OAuth.connect().then((redirect) => {
        window.location.href = redirect.data.consentUrl
      })
    }
  }
}
</script>
