import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/oauth`

const connect = () => {
  return axios.get(api + '/connect')
}

const setToken = (code) => {
  return axios.post(api + '/setToken', { code: code }, { withCredentials: true, credentials: 'include' })
}

export default {
  connect,
  setToken
}
